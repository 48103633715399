import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getSubjectTopics } from '../../services/subjects/subjectTopics';
import { LinearProgress, MenuItem, Select, Typography } from '@mui/material';
import { set } from 'lodash';
import SelectableQuestionTable from './SelectableQuestionTable';
import { getQuestionsForTopic, updateClasifiedQuestionUsageForTopic } from '../../services/question/classify-questions';

function ClassifyQuestionsScreen() {
    const { subjectId } = useParams();
    const [subjectTopics, setSubjectTopics] = useState([])
    const [selectedSubjectTopic, setSelectedSubjectTopic] = useState(null)
    const [subTopics, setSubTopics] = useState([])
    const [selectedSubTopic, setSelectedSubTopic] = useState(null)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState({
        subjectTopics: false,
        questions: false
    })


    useEffect(() => {
        loadSubjectTopics()
    }, [subjectId])

    useEffect(() => {
      if(selectedSubTopic && selectedSubTopic.id ){
        loadQuestions(selectedSubTopic.id, page, rowsPerPage)
      }
    }, [selectedSubTopic, page, rowsPerPage])
    


    const loadSubjectTopics = async() =>{
        try{
          setLoading(l => ({...l, subjectTopics: true}))
            const {data, status } = await getSubjectTopics(1, subjectId, {})
            if (status === 200) {
                setSubjectTopics(data)
            }
        }catch(error){
            console.log({error})
        }finally{
          setLoading(l => ({...l, subjectTopics: false}))
        }
    }

    const loadQuestions = async(subjectTopicId, page, size) =>{
      try{
        setLoading(l => ({...l, questions: true}))
          const {data, status } = await getQuestionsForTopic({subjectTopicId, page, size})
          if (status === 200) {
              setData(data)
              setTableData(data)
          }
        }catch(error){
          console.log({error})
        }finally{
          setLoading(l => ({...l, questions: false}))
        }
    }

    const handleChangeSubjectTopic = (e) => {
        // setSelectedSubTopic(e.target.value)
        // console.log(e.target.value)
        setSelectedSubjectTopic(e.target.value)
        if(e.target.value.subTopics && e.target.value.subTopics.length > 0){
            setSubTopics(e.target.value.subTopics)
        }else{
            setSubTopics([e.target.value])
        }
    }

    const handleChangeSubjectSubTopics = (e) => {
        setSelectedSubTopic(e.target.value)
    }

    const handleCheckboxClick = async(event, row) => {
      try{
        setLoading(l => ({...l, questions: true}))
        const {isCategorizedView} = row
        const {data, status } = await updateClasifiedQuestionUsageForTopic(selectedSubTopic.id, row.question.id, {isCategorizedView: !isCategorizedView})
        if(status === 200){
          const updatedData = tableData.map( (oldRow) => {
            if(oldRow.question.id === row.question.id){
              return data
            }
            return oldRow
          })
          setTableData(updatedData)
        }
      }catch(error){
        console.log({error})
      }finally{
        setLoading(l => ({...l, questions: false}))
      }
    }

  return (
    <>
      <Typography variant='h1'>ClassifyQuestionsScreen</Typography>
      {(loading.questions || loading.subjectTopics) && <LinearProgress />}
      <Select
        // labelId="demo-simple-select-label"
        id="main-topic-select"
        value={selectedSubjectTopic}
        label="Main topic"
        onChange={handleChangeSubjectTopic}
      >
        {
            subjectTopics.map( (subTopic) => <MenuItem key= {subTopic.id} value={subTopic}>{subTopic.name}</MenuItem>)
        }
      </Select>

      <Select
        // labelId="demo-simple-select-label"
        id="main-topic-subtopics-select"
        value={selectedSubTopic}
        label="Main topic subtopics"
        onChange={handleChangeSubjectSubTopics}
      >
        {
            subTopics.map( (subTopic) => <MenuItem key= {subTopic.id} value={subTopic}>{subTopic.name}</MenuItem>)
        }
      </Select>
      <div>
        <SelectableQuestionTable 
          page={page} 
          setPage={setPage} 
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage} 
          tableData={tableData} 
          setTableData={setTableData}
          data={data}
          handleCheckboxClick = {handleCheckboxClick}
        />
      </div>
    </>
  );
}

export default ClassifyQuestionsScreen