import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, TableSortLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; // Import MoreHorizIcon
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteMCQPastPaperQuestion, editMCQPastPaperQuestion, getMCQPastPaperQuestion } from '../../services/subjects/pastPaperQuestion';
import QuestionViewPopup from '../questions/QuestionViewPopup';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
 
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const headCells = [
  {
    id: 'index',
    numeric: true,
    disablePadding: true,
    label: 'Index',
  },
  {
    id: 'question.name',
    numeric: false,
    disablePadding: false,
    label: 'Question Name',
  },
  {
    id: 'question.id',
    numeric: true,
    disablePadding: false,
    label: 'Question',
  },
  
];

function QuestionsForPaper() {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const { paperId } = useParams();
  const { subjectId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);  
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);  
  const [openDialog, setOpenDialog] = React.useState(false);
  const [editedIndex, setEditedIndex] = React.useState('');
  const [questionView, setQuestionView] = useState({
    open: false,
    question: null,
  })
  const [viewButton,setViewButton]= useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('index')

  useEffect(() => {
    loadQuestions();
  }, [paperId]);

  const handleAddQeustion = () => {
    navigate(`/dashboard/syllabuses/selectedSyllabus/addQuestion/${subjectId}/${paperId}`, {
      state: {}
    });
  };

  const loadQuestions = async () => {
    try {
      const response = await getMCQPastPaperQuestion(paperId);
      // console.log(response.data);
      setQuestions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuOpen = (event, question) => {
    setAnchorEl(event.currentTarget);
    setSelectedQuestion(question);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit =async () => {
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleDelete = async() => {
    await deleteMCQPastPaperQuestion(paperId,selectedQuestion.id);
    loadQuestions();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSave = async () => {
     
    setOpenDialog(false);
  try {  
    await editMCQPastPaperQuestion(paperId, selectedQuestion.id, { index: editedIndex });
  }catch(error){
    console.log(error)
  }
  setEditedIndex("")
  loadQuestions();
  };

  const onViewQuestionClick = () => {
    // console.log(selectedQuestion)
    setQuestionView({ open: true,question : selectedQuestion.question });
    handleMenuClose();
  }

  const handleViewReviews = () => {
    const questionId = selectedQuestion.question.question.id;  
    navigate(`/dashboard/syllabuses/selectedSyllabus/reviews/${subjectId}/${questionId}`)
  };
  
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      [...questions]
        .sort(getComparator(order, orderBy)),
    [order, orderBy, questions],
  );


  return (
    <Container>
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        style={{ fontSize: "30px" }}
      >
        Past Paper Questions
      </Typography>
      <Box sx={{ margin: 2, padding: 2 }}>
        <TableContainer sx={{ margin: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    // align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row) => (
                <TableRow key={row.index}>
                  <TableCell>{row.index}</TableCell>
                  <TableCell>
                    {
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row.question.question.name,
                        }}
                      ></span>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row.question.question.questionText,
                        }}
                      ></span>
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuOpen(event, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      <Box sx={{ "& > :not(style)": { marginBottom: 1 } }}>
        <Fab color="primary" aria-label="add" onClick={handleAddQeustion}>
          <AddIcon />
        </Fab>
      </Box>
      <Menu
        id="question-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={onViewQuestionClick}>View Question</MenuItem>
        <MenuItem onClick={handleViewReviews}>View Reviews</MenuItem>
      </Menu>
      <QuestionViewPopup
        questionView={questionView}
        setQuestionView={setQuestionView}
        viewButton={viewButton}
      />
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit Index</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the new index value for the question.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="index"
            label="Index"
            type="text"
            fullWidth
            value={editedIndex}
            onChange={(e) => setEditedIndex(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default QuestionsForPaper;
