import React, { useState } from 'react'
import ReusableTable from '../reusableComponents/ReusableTable';
import { Checkbox } from '@mui/material';

function SelectableQuestionTable({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  tableData,
  setTableData,
  data,
  setData,
  handleCheckboxClick,
}) {
  const [checkedRows, setCheckedRows] = useState({});

  const columns = [
    { id: "id", label: "Question Number", render: (row) => row.question.id },
    { id: "name", label: "Question Name", render: (row) => row.question.name },
    {
      id: "questionText",
      label: "Question",
      render: (row) => (
        <span dangerouslySetInnerHTML={{ __html: row.question.questionText }} />
      ),
    },
    {
      id: "checkbox",
      label: "Add to classify questions",
      render: (row) => (
        <Checkbox
          checked={row.isCategorizedView}
          onChange={(event) => handleCheckboxClick(event, row)}
        />
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <ReusableTable
        columns={columns}
        data={tableData}
        checkedRows={checkedRows}
        handleCheckboxClick={handleCheckboxClick}
        page={page}
        rowsPerPage={rowsPerPage}
        count={data.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default SelectableQuestionTable