import api from "../.."
import Env from '../../../config/environments';
import { ApiEndpoint, Method } from "../../../constants"
 
const params = {};
const body = {};

export const getQuestionsForTopic = (params) => {
    
    return api(`${ApiEndpoint.QUESTION_CATEGORIZE}`, Method.GET, params, Env.BASE_URL, body)

}

export const updateClasifiedQuestionUsageForTopic = (topicId, questionId, questionSubjectTopic) => {
    
    return api(`${ApiEndpoint.QUESTION_CATEGORIZE}/topic/${topicId}/question/${questionId}`, Method.PUT, params, Env.BASE_URL, questionSubjectTopic)
}