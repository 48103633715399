import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Grid, LinearProgress, Snackbar, Typography } from '@mui/material'
import TextEditorWithTitle from './components/TextEditorWithTitle'
import QuestionStringInput from './components/QuestionStringInput'
import QuestionSelectInput from './components/QuestionSelectInput'
import SelectedSubtopicsView from './components/SelectedSubtopicsView'
import QuestionCheckBox from './components/QuestionCheckBox'
import SubmitButton from './components/SubmitButton'
import { useLocation, useParams } from 'react-router-dom'
import { getSubjectTopics } from '../../services/subjects/subjectTopics'
import { createMcqQuestion, updateMcqQuestion } from '../../services/question/mcq'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getQuestion } from '../../services/subjects/questions'
import _ from 'lodash'


  const marks = [
    {value : 0, name : '0%'},
    {value: 1, name : '100%'}
  ]

  const numbers = [2,3,4,5,6]

  const styles = {
    customSelect: {
      '& .MuiSelected-root:focus': {
        backgroundColor: 'red', // Change the selecting color when focused
      },
    },
    
  };

function MultichoiceQuestion() {

    const [originalSavedQuestionData, setOriginalSavedQuestionData] = useState({})
    const [question, setQuestion] = useState('')
    const [nAnswers, setNAnswers] = useState(numbers[2])
    const [answers, setAnswers] = useState([])
    // const [answerMarks, setanswerMarks] = useState([])
    const [name, setName] = useState('')
    // const [description, setDescription] = useState('')
    const [topic, setTopic] = useState('')
    const [selectableSubTopics, setSelectableSubTopics] = useState([]) // selected sub topoics for the current main topic
    const [allSubTopics, setAllSubTopics] = useState([]) //all selected subtopics
    const [useReview, setuseReview] = useState(false)
    const [questionReview, setQuestionReview] = useState({})
    const [mainTopics, setMainTopics] = useState([])
    const [subTopics, setSubTopics] = useState([])
    const [defaultMarks, setdefaultMarks] = useState(2)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState({})
    // const [originalQuestion, setOriginalQuestion] = useState({})
    const [allTopicesById, setAllTopicesById] = useState({})

    const [questionEditorState, setQuestionEditorState] = useState({})

    const location = useLocation();

    const subjectId = useRef(null);


    const {subjectId_} = useParams()
    // console.log({subjectId})

    useEffect(() => {
     if(subjectId_){
         subjectId.current = subjectId_
         loadMainTopics(subjectId_)
     }
    }, [])
    

    const queryPrameters = new URLSearchParams(location.search)
    const id = queryPrameters.get('id')

    // console.log({ id })
    // useEffect(() => {
    //     if(subjectId){
    //         loadMainTopics(subjectId)
    //     }
    // }, [subjectId])

    const loadMainTopics = async (subjectId) => {
        try {
            setLoading(loading => {return {...loading, mainTopicsIsLoading: true}})
            const response = await getSubjectTopics(1, subjectId)
            const { data, status } = response
            if (status === 200) {
                setMainTopics(data)
                data.length > 0 && setTopic(data[0]) //set first one as selected
                let allTopics = {}
                data.forEach(t => {
                    allTopics[t.id] = t
                    allTopics[t.id].subTopics.forEach(s => {
                        allTopics[s.id] = s
                    })
                })
                setAllTopicesById(allTopics)
            }
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(loading => {return {...loading, mainTopicsIsLoading: false}})
        }
    }

    useEffect(() => {
        if(topic) {
            if(topic.subTopics.length > 0){
                setSubTopics(topic.subTopics)
            }else{
                setSubTopics([topic])
            }
        }
    }, [topic])

    useEffect(() => {
        if(topic && allSubTopics) {
            if(topic.subTopics.length > 0){
                const subTopics = topic.subTopics.filter(s => allSubTopics.find(t => t.id == s.id))
                setSelectableSubTopics(subTopics)
            }else{
                if(allSubTopics.find(s => s.id == topic.id)){
                    setSelectableSubTopics([topic])
                }
            }
        }
    }, [topic, allSubTopics])

    useEffect(() => {
      if(id && id > 0){
          //load question
          loadQuestion(id)
          subjectId.current = id
      }
    }, [id])

    const loadQuestion = async (id) => {
        try {
            setLoading(loading => {return {...loading, questionIsLoading : true }})
            const response = await getQuestion(id)
            const { data, status } = response
            if (status === 200) {
                setOriginalSavedQuestionData(data)
                // setOriginalQuestion(data)
                const { question, multiChoiceOptions : answers } = data
                setQuestion(question.questionText)
                setQuestionEditorState(question.questionTextEditorState)
                setAnswers(answers)
                // setTimeout(() => {
                //     setNAnswers(answers.length)  
                // }, 500); 
                // setanswerMarks(answers.map(a => {
                //     const selected = marks.find(b => b.value === a.mark) 
                //     return  selected ? selected : marks[0]
                // }))
                setName(question.name)
                setdefaultMarks(question.defaultMark)
                setAllSubTopics(question.subjectTopics)
                // setAllSubTopics(question.subjectTopics.map(s => s.id))
                setuseReview(question.isReviewAvailable ? true : false)
                setQuestionReview( {
                    currentHtmlGenralFeedback : question.generalFeedback,
                    currentEditorStateGenralFeedback : question.generalFeedbackEditorState
                })

                loadMainTopics(question.subjectId)
                // setCurrentKey(currentKey + 1)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(loading => {return {...loading, questionIsLoading : false }})
        }
    }
    
    

    const handleChange = (values) =>{
        setSelectableSubTopics(previousvalues =>{
            if(previousvalues.length > values.length){ //has removed
                const removedItem = previousvalues.filter(item => !values.includes(item))
                // console.log(removedItem)
                setAllSubTopics(s => s.filter(s => !removedItem.includes(s)))
            }else{
                const addedItem = values.filter(item => !previousvalues.includes(item))
                setAllSubTopics(s => [...s, ...addedItem])
            }
            return values
        })
    } 
    
    const handleRemoveItem = (item) =>{
        setAllSubTopics(s => s.filter(s => s !== item))
        setSelectableSubTopics(s =>s.filter(s => s !== item))
    }

    useEffect(() => {
        // console.log("in use effect nAnswers, answers ")
        
        setAnswers(prevAnswers =>{
            if(prevAnswers.length < nAnswers){
                // console.log("1")
                let newAnswers = [...prevAnswers]
                for(let i = prevAnswers.length; i < nAnswers; i++){
                    newAnswers.push({answerText : '', mark : marks[0].value})
                }
                // const array = new Array(nAnswers - prevAnswers.length).fill({answerText : '', mark : marks[0].value});
                return newAnswers
                // const newAnswerMarks = new Array(nAnswers - answerMarks.length).fill(marks[0]);
                // setanswerMarks(s => [...s, ...newAnswerMarks])
             }else if(prevAnswers.length > nAnswers){
                // console.log("2")
                const remain = prevAnswers.filter((s, i) => i < nAnswers)
                return remain
                // const remainMarks = answerMarks.filter((s, i) => i < nAnswers)
                // setanswerMarks(remainMarks)
             }else{
                 return prevAnswers
             }
        })

        // setanswerMarks(prevMarks => {
        //     if (prevMarks.length < nAnswers) {
        //         // console.log("1")
        //         const array = new Array(nAnswers - prevMarks.length).fill(marks[0]);
        //         return array
        //         // const newAnswerMarks = new Array(nAnswers - answerMarks.length).fill(marks[0]);
        //         // setanswerMarks(s => [...s, ...newAnswerMarks])
        //     } else if (prevMarks.length > nAnswers) {
        //         // console.log("2")
        //         const remain = prevMarks.filter((s, i) => i < nAnswers)
        //         return remain
        //         // const remainMarks = answerMarks.filter((s, i) => i < nAnswers)
        //         // setanswerMarks(remainMarks)
        //     } else {
        //         return prevMarks
        //     }
        // })

    }, [nAnswers])

    // const serMark = (mark, index) =>{
    //     const newMarks = [...answerMarks]
    //     newMarks[index] = mark
    //     setanswerMarks(newMarks)
    // } 


    const saveMultichoiceQuestion = async() =>{

        if(!name || !question || !defaultMarks 
            // || !allSubTopics.length 
            || !answers.length || (useReview &&!questionReview)){
            alert("Please fill all fields")
            return
        }
        let totalMarks = 0
        for(let i = 0; i < answers.length; i++){
            if(!answers[i] || answers[i].length === 0){
                alert("Please fill all answers")
                return
            }
            totalMarks += answers[i].mark
        }
        if(totalMarks !== 1){
            alert("Total marks of answers must equal to 1")
            return
        }

        const {currentEditorStateGenralFeedback = '', currentHtmlGenralFeedback = ''} = questionReview

        try{
            const questionBody = {
                question: {
                    name,
                    questionText: question,
                    questionTextEditorState : questionEditorState,
                    generalFeedback: currentHtmlGenralFeedback,
                    generalFeedbackEditorState : currentEditorStateGenralFeedback,
                    defaultMark: defaultMarks,
                    questionType: { id: 1 },
                    subjectTopics: allSubTopics,
                    subject: { id: subjectId.current },
                    isReviewAvailable: useReview
                },
                "singleChoice": true,
                "shuffleChoices": false,
                "numberOfChoices": nAnswers,
                "multiChoiceOptions": answers
            }
            // console.log(questionBody)

            const response = id > 0 ? await updateMcqQuestion(id, questionBody) :  await createMcqQuestion(subjectId.current, questionBody)
            const {data, status} = response
            if(status === 200){
                // console.log(data)
                setQuestion('') 
                setNAnswers(numbers[2])
                setAnswers([])
                // setanswerMarks([])
                setName('')
                
                setOpen(true)
            }
        }catch(error){
            console.log(error)
        }
    }

    // const onChangeContent = (html, i, property) =>{
    //     //  console.log("html")
    //     //  console.log(html)
    //     // alert("html " + i + " " + property + " " + html)
    //     if(!html) return
       
    //     setAnswers(perv =>{
    //         const newAnswers = [...perv]
    //         // newAnswers[i][property] = _.cloneDeep(html)
    //         newAnswers[i][property] = html
    //         return newAnswers
    //     })
    // } 

    const onChangeContent = useCallback((html, i, property) =>{
        // console.log({i})
        //  console.log("html")
        //  console.log(html)
        // alert("html " + i + " " + property + " " + html)
        // console.log("onChangeContent " + i)
        if(!html) return
        // console.log("onChangeContent not returned " + i)
       
        setAnswers(perv =>{
            const newAnswers = [...perv]
            // newAnswers[i][property] = _.cloneDeep(html)
            newAnswers[i][property] = html
            return newAnswers
        })
    }, [setAnswers, answers]) 
    
    const handleClose = (event, reason) => {
        setOpen(false)
    }

    const getMark = (markValue) => {
        const selected = marks.find(b => b.value === markValue)
        return selected ? selected : marks[0]
    }


    const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            Okay
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const onChangeQuestionReview = (html, property) => {
        // console.log("ttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt")
        // // console.log({html})
        // console.log({property})
        setQuestionReview(preReview => {
            const newReview = {...preReview}
            newReview[property] = html
            return newReview

        })
    }

      const {question  : {questionText ='',questionTextEditorState = '', generalFeedback = '', generalFeedbackEditorState = '' } = {} , multiChoiceOptions = []} = originalSavedQuestionData
    //   console.log({questionText})
    //   console.log({questionTextEditorState})
    // console.log("*****************************************")
    // console.log(multiChoiceOptions)
    const {currentHtmlGenralFeedback = '' } = questionReview

  return (
      <>
          <Grid container spacing={2} style={{ margin: "10px", padding: '10px', flexDirection: "column" }}>
              <Grid item xs={12}  textAlign="center" >
                  <Typography variant="h4" style={{marginBottom:'30px', fontFamily: 'sans-serif', fontSize: '140%', fontWeight: 'bolder',  }}>
                      MultichoiceQuestion
                  </Typography>
              </Grid>
              <Grid>
                    {(loading.questionIsLoading || loading.mainTopicsIsLoading || loading.imageUploading) && <LinearProgress color="secondary" />}
              </Grid>
              <QuestionStringInput title="Name" value={name} setValue={setName} />
              {/* <QuestionStringInput title = "Description" value={description} setValue={setDescription}/> */}
              <QuestionSelectInput title="Main topic" value={topic} setValue={setTopic} items={mainTopics} />
              <QuestionSelectInput title="Sub topics" value={selectableSubTopics} setValue={handleChange} items={subTopics} multiSelect 
               menuItemSxProps = {{'&.Mui-selected': { backgroundColor: 'lightblue' }}}
                />
              <SelectedSubtopicsView items={allSubTopics} setAllSubTopics={setAllSubTopics} handleRemoveItem={handleRemoveItem} allTopicesById = {allTopicesById}/>
              <QuestionSelectInput title="Number of answers" value={nAnswers} setValue={setNAnswers} items={numbers} />
              {/* <NumberOfAnswersSelector nAnswers = {nAnswers} setNAnswers={setNAnswers}/> */}
              <QuestionStringInput title="Defalut marks" value={defaultMarks} setValue={setdefaultMarks} type='number' />
              {/* <DraftJSEditor /> */}
              <TextEditorWithTitle
                  title={"Question"}
                  setLoading={setLoading}
                  loading={loading}
                  editorState = {questionTextEditorState}
                  html={questionTextEditorState ? null : questionText}
                  onChangeEditorState = {setQuestionEditorState}
                  onChangeHtml={setQuestion} 
                  currentHtml={question}
              // key={currentKey}
              />

              {
                  answers.map((ansewer, i) => {
                    
                    const originalEditorState = multiChoiceOptions.length > i ? multiChoiceOptions[i].answerTextEditorState : null
                    const originalHtmlString = !originalEditorState && multiChoiceOptions.length > i ? multiChoiceOptions[i].answerText : null

                    

                    return(
                      <Grid key={i} container>
                          <TextEditorWithTitle
                              title={`Answer ${i + 1}`}
                            //   onChange={(html, index) => onChangeContent(html, index, 'answerText')}
                              setLoading={setLoading}
                              loading={loading}
                              html={originalHtmlString}
                              editorState = {originalEditorState}
                              onChangeEditorState = {(editor) => onChangeContent(editor, i, 'answerTextEditorState')}
                              onChangeHtml={(html) => onChangeContent(html, i, 'answerText')} 
                              currentHtml={ansewer.answerText}
                              //    key={ (i + 1) * 100}
                              index={i}
                          />
                          <QuestionSelectInput title="Marks"
                              value={answers[i].mark}
                              setValue={(mark) => {
                                const updatedAnswers = [...answers];  
                                updatedAnswers[i].mark = mark.value;  
                                setAnswers(updatedAnswers);  
                            }}
                              items={marks}
                              getVale = {getMark}
                            //   key={ (i + 1) * 100}
                          />
                      </Grid>)})
              }
              <QuestionCheckBox title={"Review available"} checked={useReview} setChecked={setuseReview} />

              {useReview && <TextEditorWithTitle
                  title={"Detail review"}
                  //   onChange={(html) => setQuestionReview(html)}
                  html={generalFeedback}
                  editorState={generalFeedbackEditorState}
                  currentHtml={currentHtmlGenralFeedback}
                  onChangeEditorState={editorState => onChangeQuestionReview(editorState, 'currentEditorStateGenralFeedback')}
                  onChangeHtml={html => onChangeQuestionReview(html, 'currentHtmlGenralFeedback')}
              />}

              <SubmitButton onClick={saveMultichoiceQuestion} editMode = {id}/>

          </Grid>
          <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              message= {id ? "Question updated successfully" : "Question created successfully"}
              action={action}
          />
      </>
  )
}

export default MultichoiceQuestion