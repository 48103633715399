// const BASE_URL = "https://devapi.papere.lk"
// const BASE_URL = "https://qa.papere.lk"
// const BASE_URL = "http://localhost:8080"
 const BASE_URL = "https://papere.lk"

const env = {
    BASE_URL: BASE_URL,
    AUTH_SERVICE: `${BASE_URL}/api/v1/auth`,
}

export default env;